.custom-control-label {
  // Background-color and (when enabled) gradient
  &::before {
    border: $custom-control-indicator-border-width solid
      $custom-control-indicator-border-color;
    @if $enable-transitions {
      transition: $input-transition;
    }
  }

  span {
    position: relative;
    top: 2px;
  }
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-input {
  &:active ~ .custom-control-label::before {
    border-color: $custom-control-indicator-active-border-color;
  }
}

// Alternative custom control
.custom-control-alternative {
  .custom-control-label {
    // Background-color and (when enabled) gradient
    &::before {
      border: 0;
      box-shadow: $input-alternative-box-shadow;
    }
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          box-shadow: $input-focus-alternative-box-shadow;
        }
      }
    }

    &:active ~ .custom-control-label::before,
    &:focus ~ .custom-control-label::before {
      box-shadow: $input-alternative-box-shadow;
    }
  }
}

// Checkboxes
.custom-checkbox {
  .custom-control-input ~ .custom-control-label {
    cursor: pointer;
    font-size: $font-size-sm;
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-checked-border-color;
        }
        &::after {
          background-image: $custom-checkbox-indicator-icon-checked;
        }
      }
    }

    &:disabled {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-disabled-bg;
        }
      }

      &:checked {
        &::before {
          border-color: $custom-control-indicator-checked-disabled-bg;
        }
      }
    }
  }
}

// Radios
.custom-radio {
  .custom-control-input ~ .custom-control-label {
    cursor: pointer;
    font-size: $font-size-sm;
  }

  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-checked-border-color;
        }
        &::after {
          background-image: $custom-radio-indicator-icon-checked;
        }
      }
    }

    &:disabled {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-disabled-bg;
        }
      }

      &:checked {
        &::before {
          border-color: $custom-control-indicator-checked-disabled-bg;
        }
      }
    }
  }
}

// Toggles
.custom-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 1.5rem;

  input {
    display: none;

    &:checked {
      + .custom-toggle-slider {
        border: $custom-control-indicator-border-width solid
          $custom-control-indicator-checked-border-color;

        &:before {
          background: $custom-toggle-checked-bg;
          transform: translateX(1.625rem);
        }
      }
    }

    &:disabled {
      + .custom-toggle-slider {
        border: $custom-control-indicator-border-width solid
          $custom-control-indicator-disabled-bg;
      }

      &:checked {
        + .custom-toggle-slider {
          border: $custom-control-indicator-border-width solid
            $custom-control-indicator-disabled-bg;

          &:before {
            background-color: lighten(
              $custom-control-indicator-checked-bg,
              10%
            );
          }
        }
      }
    }
  }
}

.custom-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: $custom-control-indicator-border-width solid $input-border-color;
  border-radius: 34px !important;
  background-color: transparent;

  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    border-radius: 50% !important;
    background-color: $custom-toggle-slider-bg;
    transition: $input-transition;
  }
}

.credential-name-mfa {
  display: inline-block;
}

.credential-name-mfa-delete {
  display: inline-block;
  float: right;
}

/* Sharepass code */
.form-formik {
  input {
    background-color: unset;
    box-shadow: unset !important;
    border: 1px solid #2b3553;
    border-radius: 4px;
  }

  textarea {
    width: 100%;
    min-height: 80px;
    max-height: 200px;
    color: #fff;
    background-color: unset;
    border: 1px solid #2b3553;
    border-radius: 4px;
    padding: 10px 18px;
  }

  textarea:read-only, input:read-only {
    color: #ccc;
  }

  textarea:focus-visible {
    border: 1px solid #1d8cf8;
    outline: unset;
  }

  .scan-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #2b3553;
    border-radius: 4px;
    width: 100%;
    height: 107px;
    padding: 10px;
    margin-bottom: 8px;
  }

  .font-qr {
    cursor: pointer;
    font-size: 35px;
  }

  .input-camera {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    .input-file {
      padding: 40px 32% !important;
    }
  }

  .custom-border {
    border: 1px solid #2b3553;
    border-radius: 4px;
  }

  .custom-qr-data {
    border: 1px solid #2b3553;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .custom-font-icon {
    cursor: pointer;
    color: #ff4757;
    margin-right: 5px;
    font-size: 13px;
  }

  .card-collapse .card .card-header {
    border: 1px solid #2b3553;
  }

  hr {
    border-top: 1px solid #2b3553;
  }

  .nav-pills .nav-item i {
    line-height: 35px;
  }

  .nav-pills .nav-item .nav-link {
    padding: 8px 10px;
  }

  .collapse-settings {
    border: 1px solid #2b3553;
    border-top: unset;
  }

  .btn-add {
    margin: 0;
    padding: 10px 15px;
    margin-left: 3px;
  }

  .flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .ip-address-p {
    margin: 0 30px 0 0;
    font-size: 13px;
    width: 100px;
  }

  /* Datetime  */
  .rdtSwitch,
  .rdtCounters {
    color: #0d6efd;
  }

  .custom-switch {
    margin: -15px 0 15px 0;
    width: fit-content;
  }

  .custom-switch .custom-control {
    .custom-control-label {
      margin: -10px 0 15px 0;
    }
  }

  .custom-control-input {
    width: 1.25rem !important;
  }

  .btn-lock {
    width: 35px;
    cursor: pointer;
  }

  .custom-switch .custom-control-label {
    pointer-events: none;
    cursor: unset;
    /*opacity: 0.5;*/
  }

  .custom-switch .custom-control {
    margin-top: -15px !important;
  }

  // div for new label form
  .new-label {
    text-align: left;

    .error {
      margin: 0 !important;
    }
  }

  .btn-forgot {
    width: 100%;
  }

  .flex .form-group {
    min-width: 32.4%;
    margin-right: 5px;
  }

  .flex .form-group.check {
    min-width: 28%;
    margin-right: 5px;
    .flex input {
      width: 10% !important;
    }

    .flex .custom-checkbox {
      width: 7% !important;
    }
  }

  .chexbox-any {
  }

  .filter {
    input[type="checkbox"]:indeterminate {
      background-color: #ff4757 !important;
    }
    input[type="checkbox"]:indeterminate::after {
      background-color: #ff4757 !important;
    }
    input[type="checkbox"]:indeterminate::before {
      background-color: #ff4757 !important;
    }
  }
}

.ns-mode-switch {
  h4 {
    margin-bottom: 0px;
  }

  .custom-switch {
    margin-bottom: 25px;
  }
}

.card-wizard {
  .card-body {
    min-height: 335px;
  }
}

.btn-wizard {
  width: 205px;
  height: 130px;
  .btn-wizard-title {
    font-size: 1rem;
  }
}

@media (max-width: 585px) {
  .btn-wizard {
    width: 185px;
    height: 120px;
  }
}

.monospace-font {
  font-family: monospace;
}
/* End Sharepass code */
