// example pages and sections
@import "assets/scss/example-pages.scss";
@import "assets/scss/footer.scss";
@import "assets/scss/sharepass-auth.scss";
@import "assets/scss/sidebar-and-main-panel.scss";


.main-panel, .modal-content {
    background: #1b1b1b;
}

.body-consumptions .custom-card-chart {
    background: #1f1f1f !important;
}

.card {
    background: #1f1f1f;
}

.custom-card {
    background: #242424!important;
}

.dashboard .storage .custom .card {
    background: #1f1f1f;
    border-color: #ccc!important;
}

.react-select .react-select__control .react-select__value-container--has-value .react-select__single-value {
    color: #fff;
}

.text-primary {
    color: #ccc!important;
}

.white-content {
    .react-select .react-select__control .react-select__value-container--has-value .react-select__single-value {
        color: #000;
    }
    
    .text-primary {
        color: #000!important;
    }
}


.navbar-receiving {
    background: linear-gradient(0deg, #4B08A1 0%, #32066c 100%)!important;
    color: #fff;
}

.profile .theme .theme-mode-selected {
    box-shadow: 0 0 10px #4B08A1;
}

.sidebar .logo .logo-mini .logo-img img {
    background: none!important;
}

.sidebar .logo .simple-text {

    line-height: 45px!important;

}

.navbar .photo:hover {
    color: #4B08A1!important;
    border-color: #4B08A1!important;
}