
.white-content {
  .footer{
    .nav-link {
      color: $primary;
    }
  }
}

.wrapper-login {
  .footer{
    .nav-link, .copyright {
      color: #fff!important;
    }
  } 
}

.white-content {
  .enroll-security-key-page {
    .footer{
      .nav-link, .copyright {
        color: #fff!important;
      }
    }
  }
}