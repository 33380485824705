/* Custom temporary style to show a harcoded name (login) on navbar */
.profile-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 2vh;
  font-weight: 600;
}

/* This stlyes are on  
    Forgot,
    Login,
    ResendLink and
    Register
components */
.card-header-sp {
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3% !important;

  img {
    max-width: 110px;
  }
}

.card-header-sp-mobile {
  height: 80px;

  img {
    max-width: 55px;
  }
}

.card-header-sp-extension {
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3% !important;

  img {
    max-width: 80px;
  }
}

/* This stlye  is on Register component */
.card-register {
  /* Input check on register to agree terms and conditions */
  .form-check-input {
    margin-top: 2px !important;
    margin-left: -15px !important;
  }

  /*------------react-select-----------*/
  .react-select .react-select__control {
    border: 1px solid #cad1d7;
    margin-bottom: 10px;
  }

  .react-select
    .react-select__control
    .react-select__value-container--has-value
    .react-select__single-value {
    color: #222a42;
  }

  .react-select .react-select__control.react-select__control--is-focused {
    border: 1px solid #1d8cf8 !important;
  }

  .react-select .react-select__control:focus {
    border: 1px solid #1d8cf8 !important;
  }

  .react-select__control {
    border-color: #cad1d7 !important;
  }

  .css-tlfecz-indicatorContainer {
    color: red;
  }

  .css-1s2u09g-control:hover {
    border: 1px solid #cad1d7;
  }

  .css-tlfecz-indicatorContainer:hover {
    border-color: #cad1d7;
  }

  .css-1pahdxg-control {
    box-shadow: none;
  }

  .text-dark,
  h3 {
    color: #222;
  }

  .css-tj5bde-Svg {
    color: #222a42;
  }

  .react-select {
    margin-bottom: 10px;
  }
  /*----------react-select-end----------*/

  .form-group > p {
    font-weight: 500;
    color: #222a42;
    margin: 5px 0 0 0;
  }

  p {
    margin-top: -10px !important;
    font-size: 11px;
    color: #ec250d;
  }

  .info-p {
    color: #1d8cf8;
    text-align: center;
  }

  .align-center {
    text-align: center;
  }

  .card-footer {
    padding: 0 15px 15px 15px !important;
    margin-top: -10px !important;
  }

  .terms-conditions {
    color: #184c97;
    font-weight: 300;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
  }
}
/* end card-register */

.card-login {
  .card-body {
    height: 130px !important;
  }
  .error-field {
    color: #ec250d;
    font-size: 11px;
    text-align: left;
    margin: -10px 0 10px 0;
  }
  .error-p {
    color: #ec250d;
    font-size: 11px;
    text-align: center;
    margin: 0;
  }
  .p-info {
    color: $info !important;
    width: 95%;
    text-align: center;
    place-self: center;
  }
}

.modal-body {
  height: 360px;
  overflow-y: scroll;

  p {
    border-radius: 4px;
    border: 1px solid #cad1d7;
    text-align: justify;
    padding: 0px 10px;
  }
}

.modal-content .modal-footer {
  justify-content: right;
}

/* Login */
/* .login-page {
  .card-body {
    height: 110px !important;
  }
} */
/* end login-page */

.register-dark {
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

/* Forgot  */
.card-forgot {
  .danger-p {
    height: 25px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  p {
    font-size: 11px;
    color: #ec250d;
  }
  .p-info {
    color: $info !important;
  }

  .body-reset {
    /* height: 355px !important; */

    .clearfix:after {
      content: "" !important;
      display: table !important;
      clear: both !important;
    }

    .input-group {
      margin: 0;
    }

    .danger-p {
      height: 10px;
    }

    .incorrect {
      height: 70px;
    }
  }

  .reset-info {
    text-align: center;
  }
}

.card-forgot .card-body {
  padding-bottom: 0 !important;
}

.card-forgot .card-footer {
  padding-top: 0 !important;
}
/* end forgot */

.enroll-request-code {
  color: #939393 !important;
  cursor: pointer !important;
}

.wwpass-icon {
  width: 24px;
  margin-left: 5px;
  margin-top: -2px;
}

.fa-apple {
  height: 15px;
}

.alert-banner {
  position: absolute;
  padding: 2px;
  top: 0px;
  z-index: 99999;
  border-radius: 0;
  width: 50vw;
  text-align: center;
  font-size: 10px;
  margin: 0 25vw;
}

.bgWhite {
  background-color: #fff;
}
