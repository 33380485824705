.content-scroll {
  height: 77vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 10px;
}

.content-scroll-notify {
  height: 84vh;
}

/* List of secrets */
#scrollable-div {
  height: 66vh;
}

#scrollable-div-user {
  height: 50vh;
}

/* List of templates */
#scrollable-template-div {
  height: 69vh;
}

.dashboard {
  .plan {
    /* border: 1px solid #525f7f; */
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: 13px;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .danger {
    p {
      color: $danger !important;
    }
  }

  .info {
    p {
      color: $info !important;
    }
  }

  .warning {
    p {
      color: $warning !important;
    }
  }

  .success {
    p {
      color: $success !important;
    }
  }

  .secrets-usage {
    height: 95px;
    padding: 10px 10px;

    .card-description {
      margin-bottom: 5px;
    }

    .card-description-p {
      margin-bottom: 5px;
      font-size: 1.5em;
    }
  }

  .info-plan {
    div {
      font-size: 25px;
      text-align: center;
    }
  }

  .subscribe-notification {
    font-size: 0.875rem;
  }

  .info-plan.card-title small {
    font-size: 40%;
  }

  .important-text {
    font-size: 1.43rem;
  }

  .storage {
    .card {
      box-shadow: none;
    }

    .custom .card {
      background-color: #1d1d2c;
    }
  }

  .custom-card-plan {
    background-color: #1d1d2c;
    label {
      font-size: 15px;
      font-weight: 600;
      margin: 0;
    }

    .date {
      padding: 0;
    }
  }
}

.alert-danger-hard {
  background-color: #f63b3b !important;
}

.title-dashboard {
  font-weight: 300 !important;
}

.title-dashboard i {
  font-size: 20px !important;
}

.custom-notify {
  margin-right: 10px !important;
  min-width: 25% !important;
}
