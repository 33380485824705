.btn {
  & + .btn {
    margin-left: 4px;
  }
}
.btn-group + .btn-group {
  margin-left: 4px;
}

.btn-black {
  background-color: #000;
  background: #000;
}

.btn-purpose {
  color: #fff!important;
  padding: 0;
}

.white-content {
  .navbar-transparent {
    .btn-purpose {
      color: #1e1e2f!important;
    }
  }
}