.profile {
  .theme {
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;

    img {
      width: 300px;
    }

    @media screen and (max-width: 600px) {
      img {
        width: 80%;
        margin-top: 20px;
      }
    }

    .theme-mode-selected {
      box-shadow: 0 0 10px #3358f4;
    }

    .theme-mode-preview:hover {
      cursor: pointer;
    }
  }
}
