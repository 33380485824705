.wrapper {
  &.wrapper-full-page {
    overflow: hidden;
  }
}

.sidebar,
.off-canvas-sidebar {
  &[data="black"] {
    //@include linear-gradient($danger-states, $danger);
    background: #4B08A1;
    &:before {
      border-bottom-color: $primary;
    }
  }
}

.sidebar .logo .logo-mini .logo-img img {
  border-radius: 2px;
  width: 40px;
  padding: 3px;
  background: #fff;
}
