.no-overflow {
  overflow-y: hidden;
}

/* customize scrollbar css */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f0f8ff;
}

::-webkit-scrollbar-track {
  border: 1px #787878 solid;
  /* border-radius: 10px; */
  -webkit-box-shadow: 0 0 6px #c8c8c8 inset;
}
::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border: 1px solid #ccc;
  /* border-radius: 16px; */
}
::-webkit-scrollbar-thumb:hover {
  background-color: #344675;
  border: 1px solid #333333;
}
::-webkit-scrollbar-thumb:active {
  background-color: #344675;
  border: 1px solid #333333;
}

/* .form-control {
  border-color: #1d8cf8;
}

.form-control:focus {
  border-color: #1d8cf8 !important;
}
 */
/* .input-group .form-control:last-child {
  border-right: 1px !important;
  border-left: 1px !important;
} */

.card-pricing .card-body .card-title {
  font-size: 3.75em !important;
}

.card-pricing .card-body .card-prices .plan {
  margin-top: -20px !important;
}

.card-pricing .card-body .card-prices .text-on-back {
  font-size: 6.5em !important;
  margin-top: -140px !important;
}

.card-pricing .card-body .card-prices .text-on-front {
  font-size: 3.6em !important;
}

.card-pricing:hover {
  z-index: 3;
  margin-top: -1.75em !important;
  box-shadow: 0px 0px 40px 15px rgba($default-states, 0.3);
  transition: z-index 0.4s;
  transition: box-shadow 0.4s;
  transition: margin-top 0.4s;

  .card-body .card-prices .text-on-front {
    font-size: 4.3em !important;
    transition: font-size 0.4s;
  }

  .card-body .card-title {
    font-size: 4em !important;
    transition: font-size 0.4s;
  }

  .card-body .card-prices .plan {
    margin-top: -48px !important;
    font-size: 0.8em !important;
    transition: font-size 0.4s;
    transition: margin-top 0.4s;
  }

  .card-body .card-prices .text-on-back {
    font-size: 7.2em !important;
    margin-top: -140px !important;
    transition: font-size 0.4s;
  }

  ul li {
    font-size: 1.35em !important;
    transition: font-size 0.4s;
  }
}

.redirect-positioning {
  margin-top: calc(35vh - 50px);
}

.btn-sm {
  font-size: 0.75rem;
}

.clickable {
  cursor: pointer !important;
}

.reset-info-success {
  color: #226133 !important;
}

input[readonly],
input[disabled] {
  color: #6e6e6e !important;
  background-image: none;
}

.grecaptcha-badge {
  bottom: 90px !important;
}

.eee-field {
  border-color: #ffa538 !important;
}
.card-stats-widget {
  border: 2px solid #1d1d2c !important;
}

.storage-exceeded {
  border: 2px solid #ec250d !important;
  box-sizing: border-box !important;
  width: 100% !important;
}

tr .actions-right {
  opacity: 0;
}

tr:hover .actions-right {
  opacity: 1;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.laeu-switch {
  float: left;
}

.btn-table-header {
  padding: 5px 10px !important;
}

.laeu-warning {
  float: left;
  margin-top: -10px;
}

.tab-content.tab-space.tab-subcategories.secret-setting {
  padding-bottom: 0;
}

.box-ips {
  border: 1px solid #2b3553;
  height: 90px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 0 5px;
}

.box-keys {
  border: 1px solid #2b3553;
  height: 170px;
  border-radius: 4px;
  padding: 10px;
}

.box-keys {
  .key-row {
    border-bottom: 1px solid #2b3553;
    padding: 8px 8px 0 8px;
  }
  .key-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .key-row:last-of-type {
    border-bottom: none;
  }
}

.credential-name-mfa {
  cursor: default;
}

.spinner {
  animation: spin infinite 1s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.setting-tab {
  width: 120px;
  margin: 5px !important;
}

.fixed-table-width {
  min-width: 800px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.u-link-cursor {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.template-row {
  background-color: #242434;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 10px;
}

.btn-group-xl > .btn.btn-icon,
.btn.btn-icon.btn-xl,
.navbar .btn-group-xl.navbar-nav > a.btn.btn-icon,
.navbar .navbar-nav > a.btn.btn-icon.btn-xl {
  height: 5.6rem;
  min-width: 5.6rem;
  width: 5.6rem;
  border-radius: 50% !important;
}

.fa-wizard-badges {
  position: initial !important;
  top: 0 !important;
  transform: none !important;
  -webkit-transform: none !important;
  font-size: 1.35em !important;
}

.btn-wizard-red:hover,
.btn-wizard-red {
  background-color: #f63b3b !important;
  border-color: #f63b3b !important;
  background-image: none !important;
}

/*.badges-list > .badge-item:first-child {
  margin-left: 0 !important;
}*/

.badges-list > .badge-item {
  margin-left: 7px;
  margin-right: 7px;
}

@media (max-width: 620px) {
  .wizard-container .card-footer > div {
    text-align: center !important;
    float: none !important;
    padding: 0 !important;
  }

  .btn-wd {
    width: 150px !important;
  }
}

.card-wizard .tab-content {
  padding: 0 !important;
}

.ttl-approx {
  text-transform: uppercase;
}

/**************************BEGIN: Timeline horizontal*************************************/
.tlh-wrapper {
  padding: 14px;
  margin-bottom: 14px;
}
.tlh-timeline-horizontal {
  display: flex;
}
.tlh-event {
  flex: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
.tlh-event .tlh-dot {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #ccc;
  border-radius: 50%;
  z-index: 2;
}
.tlh-event:not(:last-child):after {
  content: "";
  border: 1px solid #ccc;
  position: absolute;
  width: calc(100%);
  top: 7px;
  left: 17px;
}

.tlh-event:last-child:before {
  content: "";
  border: 1px solid #ccc;
  position: absolute;
  width: calc(100% - 18px);
  top: 10px;
  left: 17px;
}

.tlh-event:first-child:after {
  content: "";
  border: 1px solid #ccc;
  position: absolute;
  width: calc(100% + 16px);
  top: 10px;
  left: -1px;
}

.tlh-ttl-zone {
  color: #fff;
  margin-bottom: -10px;
}

.tlh-event .tlh-stage-name {
  /*max-width: max(0px, calc((100% - 120px) * 999));*/
  color: #fff;
  overflow: hidden;
}
.tlh-contained-in-width {
  width: 400px;
}
.tlh-event > div {
  width: 100%;
  display: flex;
}

/*----*/
.tlh-event.tlh-filled .tlh-dot {
  border-color: #027f00;
  margin-left: 2px;
}
.tlh-event.tlh-filled:not(:last-child):after {
  border-color: #027f00;
  border-width: 4px;
}
.tlh-event.tlh-filled .tlh-dot {
  border-color: #027f00;
  background-color: #027f00;
}
.tlh-event.tlh-filled .tlh-stage-name {
  max-width: none;
  overflow: initial;
}

/*----*/

.tlh-event.tlh-filled-transition:after {
  /*border: 16px solid transparent !important;*/
  border-image: linear-gradient(45deg, #027f00, #fe0000) !important;
  border-image-slice: 1 !important;
  border-width: 4px !important;
}

.tlh-event.tlh-filled-transition {
  width: 75%;
}

/*----*/

.tlh-event:last-child .tlh-stage-name {
  margin-top: 4px;
}

.tlh-event.tlh-filled-end .tlh-dot {
  border-color: #fe0000;
}
.tlh-event.tlh-filled-end:not(:last-child):after {
  border-color: #fe0000;
  border-width: 4px;
}
.tlh-event.tlh-filled-end .tlh-dot {
  border-color: #fe0000;
  background-color: #fe0000;
  margin-left: calc(100% + 2px);
}

/*----*/

.tlh-event .tlh-arrow-back {
  border: solid #ccc;
  border-width: 0 2px 2px 0;
  display: inline-block;
  height: 16px;
  width: 16px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  box-sizing: border-box;
  margin-top: 3px;
}

.tlh-event .tlh-arrow-forward {
  border: solid #ccc;
  border-width: 0 2px 2px 0;
  display: inline-block;
  height: 16px;
  width: 16px;
  transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  box-sizing: border-box;
  margin-left: calc(100% - 17px);
  margin-top: 3px;
}

.tlh-event.tlh-filled:last-child:before {
  border-color: #027f00;
  border-width: 4px;
  margin-top: -3px;
}

.tlh-filled .tlh-arrow-forward {
  border: solid #027f00;
  border-width: 0 4px 4px 0;
}
/**************************END: Timeline horizontal*************************************/

.spot-error-field {
  margin-top: -8px;
  height: 24.5px;
  width: 100%;
  .error {
    margin: 0;
  }
}

.btn-lock-unlock i {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.btn-lock-unlock i:hover {
  transform: scale(1.1);
}

.ReactTable .rt-td,
.ReactTable .rt-thead .rt-th .rt-resizable-header-content {
  text-align: center;
  line-height: normal !important;
}

thead.rt-thead {
  padding: 0 20px 0 11px;
}

.btn-sharepass {
  color: #fff !important;
  border-color: #fff !important;
  /*transition: opacity 0.5s ease-out;
  opacity: 1;*/
}

.btn-sharepass-mini {
  display: none;
  /*transition: opacity 0.5s ease-out;
  opacity: 0;*/
}

.sidebar-mini {
  .btn-sharepass {
    display: none;
    /*transition: opacity 0.5s ease-out;
    opacity: 0;*/
  }

  .btn-sharepass-mini {
    display: block;
    border-color: #fff !important;
    margin: auto !important;
    /*transition: opacity 0.5s ease-out;
    opacity: 1;*/
  }
}

.sidebar:hover {
  .btn-sharepass {
    display: inline;
    /*margin-left: auto !important;
    margin-right: auto !important;
    transition: opacity 0.5s ease-out;
    opacity: 1;*/
  }

  .btn-sharepass-mini {
    display: none;
    /*transition: opacity 0.5s ease-out;
    opacity: 0;*/
  }
}

.modal-dialog.modal-welcome {
  max-width: 700px;
  img {
    max-width: 70%;
  }
  .card {
    background: transparent;
    margin-bottom: 15px;
    margin-top: -15px;
  }

  .btn-welcome {
    width: 128px;
  }

  .modal-content .modal-footer {
    justify-content: space-between !important;
  }
}

@media (max-width: 1199.98px) {
  .btn-sharepass-mini {
    display: none !important;
  }

  .btn-sharepass {
    display: none !important;
  }

  .nav-open {
    .btn-sharepass {
      display: inline !important;
    }
  }
}

.rdtTime {
  color: #0d6efd !important;
}

.input-uppercase .react-code-input input {
  text-transform: uppercase;
}

.input-pr-0 input {
  padding-right: 0 !important;
}

.file-secret-spot {
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: center;
  padding: 40px 0;
  cursor: pointer;
  margin-bottom: 20px;
}

#purposeModeSelector .react-select__input-container {
  display: none;
}

.wider-prepend {
  width: 56.64px;
}

.wider-prepend.btn,
.copy-data {
  margin: 0 !important;
  line-height: 1.12;
  padding: 11px 15px;
}

.template-selector-container {
  display: inline-block;
  max-width: 400px;
  min-width: 150px;
  width: 20vw;
}

/**
* BEGIN: Loading animation
*/
// Works in Chromium-based browsers ✅
// CSS Future Tip ✨ (Houdini 🧙‍♂️ property rule)
// Browser Check: https://caniuse.com/mdn-css_at-rules_property

@property --value {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0deg;
}

@property --width-ratio {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

@property --scale {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

:root {
  --width: 0.5vmin;
  --duration: 7s;
  --size: 50vmin;
  --logo: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDAuNTgxIiBoZWlnaHQ9IjU3Ljg0NiIgdmlld0JveD0iMCAwIDQwLjU4MSA1Ny44NDYiPgogIDxkZWZzPgogICAgPGxpbmVhckdyYWRpZW50IGlkPSJsaW5lYXItZ3JhZGllbnQiIHgxPSIwLjkxMSIgeTE9IjAuMDgyIiB4Mj0iMC4xODUiIHkyPSIwLjkxIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2UyMDBmZiIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjAuMTA3IiBzdG9wLWNvbG9yPSIjZWVhM2Y3Ii8+CiAgICAgIDxzdG9wIG9mZnNldD0iMC40NDMiIHN0b3AtY29sb3I9IiNlMWQwZTMiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIwLjU3NiIgc3RvcC1jb2xvcj0iI2ZmZiIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM4N2NjZjAiLz4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSJQYXRoXzEiIGRhdGEtbmFtZT0iUGF0aCAxIiBkPSJNOTMzLjYxMyw2OTkuNDkybC0zMS40NTMsMzEuODVoMjAuMzA4bC0xOC43OCwyNiwzOS4wNTMtMzQuNzk1SDkyMS4wN1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05MDIuMTU5IC02OTkuNDkyKSIgZmlsbD0idXJsKCNsaW5lYXItZ3JhZGllbnQpIi8+Cjwvc3ZnPgoK");
  @supports not (background: paint(foo)) {
    --duration: 4s;
  }
}

.covered-inner-rings {
  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  perspective: var(--size);

  &:before,
  &:after {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 1);
    border-radius: 50%;
    --width-ratio: 1;
    border: calc(var(--width) * var(--width-ratio)) solid transparent;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    background: conic-gradient(
        from calc(var(--value) * 3),
        #fff,
        transparent,
        #fff,
        #fff,
        #fff,
        transparent,
        transparent,
        #fff,
        transparent,
        #fff,
        #fff,
        #fff,
        transparent,
        transparent,
        transparent
      )
      border-box;
    mask-composite: exclude;
    @supports (background: paint(foo)) {
      animation: covered-inner-ring var(--duration) ease-in-out infinite;
    }
    @supports not (background: paint(foo)) {
      animation: covered-inner-ring-legacy var(--duration) ease-in-out infinite;
    }
    --start: 180deg;
    --value: var(--start);
    --scale: 1;
    transform: rotateY(var(--value)) rotateX(var(--value)) rotateZ(var(--value))
      scale(var(--scale));
  }

  &:before {
    --start: 180deg;
  }

  &:after {
    --start: 90deg;
  }

  > .covered-inner-rings {
    &:before {
      --start: 360deg;
    }

    &:after {
      --start: 270deg;
    }
  }
}

@keyframes covered-inner-ring {
  from {
    --value: var(--start);
    --scale: 1;
  }
  50% {
    --scale: 1.2;
    --width-ratio: 1.5;
  }
  70% {
    --scale: 1;
    --value: calc(var(--start) + 180deg);
    --width-ratio: 1;
  }

  80% {
    --scale: 1.2;
    --width-ratio: 1.5;
  }

  to {
    --value: calc(var(--start) + 360deg);
    --scale: 1;
    --width-ratio: 1;
  }
}

@keyframes covered-inner-ring-legacy {
  from {
    --value: var(--start);
    --scale: 1;
    transform: rotateY(var(--value)) rotateX(var(--value)) rotateZ(var(--value))
      scale(var(--scale));
  }

  70% {
    --scale: 1.2;
    --value: calc(var(--start) + 180deg);
    transform: rotateY(var(--value)) rotateX(var(--value)) rotateZ(var(--value))
      scale(var(--scale));
  }

  to {
    --value: calc(var(--start) + 360deg);
    --scale: 1;
    transform: rotateY(var(--value)) rotateX(var(--value)) rotateZ(var(--value))
      scale(var(--scale));
  }
}

.covered {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  .covered-inner {
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
    background: $primary;
  }
}

/**
* END: Loading animation
**/

.eee-secret-schema {
  border-color: #ffa538 !important;
  border: 1px solid;
  border-radius: 5px;
  margin-top: 10px;
}

/**
* Custom secret schema
**/
.rjsf {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  i.glyphicon {
    display: none;
  }
  .btn-add::before {
    content: "Add";
  }
  /*.array-item-move-up::before { content: "\21E7"; }
  .array-item-move-down::before { content: "\21E9"; }*/
  .array-item-remove::before {
    content: "Remove";
  } //\2012

  .col-xs-offset-9 {
    margin-left: 75%;
  }

  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .array-item-list {
    display: block !important;
    margin: 0px !important;
  }

  .array-item {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px 0 !important;
    border: 1px #ffa538 solid;
    border-radius: 5px;
    padding: 15px;
  }

  .btn-group {
    margin-top: 64px;
  }

  .secret-details-icons {
    font-size: 20px !important;
  }
}
