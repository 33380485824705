.card-price {
  border-radius: 4px !important;
  box-shadow: none !important;

  .card-footer {
    display: flex;
    justify-content: flex-end;

    .payments {
      opacity: 0.7;
      color: #fff;
    }

    .payment-options {
      margin-top: 2px;
    }

    .payment-icon {
      margin-right: 10px;
      font-size: 20px;
    }
  }
}

.card-item-price {
  box-shadow: none !important;
  background: #1f212e !important;
  border-radius: 20px !important;
  width: 95% !important;
  margin-left: 2.5%;

  .card-body {
    padding-top: 40px !important;
  }

  .card-price-title {
    margin-left: 10px;
  }

  .custom-title {
    font-size: 18px;
    font-weight: 600;
  }

  .custom-sub-title {
    font-size: 12px;
    color: #6f6f73;
    /* color: #525f7f; */

    span {
      /* color: #f5c451; */
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .icon-price {
    transform: rotate(-15deg);
  }
  .icon-price.mini {
    font-size: 20px !important;
  }
  .icon-price.small {
    font-size: 30px !important;
  }
  .icon-price.medium {
    font-size: 35px !important;
  }
  .icon-price.large {
    font-size: 45px !important;
  }

  .check-item {
    margin-right: 15px;
  }

  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  .price-title {
    position: absolute;
    display: flex;
    right: 20px;
    height: 45px;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
  }

  .custom-sub-item {
    display: flex;
    align-items: center;
  }

  .title-bold {
    font-weight: 600;
    color: #fff;
    margin-right: 5px;
  }
  .item-sub-title {
    color: #6f6f73;
  }

  .btn-plan {
    display: flex;
    justify-content: center;
  }
} /* Backgroud colors */
.plan-selected {
  background: #fafafa !important;
  .title-bold {
    color: #1f212e;
  }

  .custom-sub-title {
    span {
      color: #f5c451;
    }
  }

  .custom-title {
    color: #1f212e;
  }

  .price-title {
    color: #1f212e;
  }

  hr {
    border-top: 1px solid #1f212e;
  }
}

.card-item-price::before,
.card-item-price::after {
  content: "";
  z-index: 1;
  position: absolute;
  width: 50%;
  margin: 0 10%;
  /* display: flex;
  justify-content: center; */
}

.card-item-price::before {
  /* border-top: 1px solid; */
  left: 0;
  right: 0;
  top: 0;
  height: 2px;
}

.card-item-price::after {
  border-radius: 60%;
  left: 15%;
  right: 15%;
  height: 60px;
  border-bottom: 1px solid #1f212e;
  top: -40px;
  background-color: #27293d;
}

.row-price-card {
  margin: 0 5px;
}

.white-content {
  .card-price {
    border-radius: 4px !important;
    /* box-shadow: none !important; */

    .card-footer {
      display: flex;
      justify-content: flex-end;
      background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%) !important;
      .payments {
        opacity: 0.9;
        color: #fff;
      }

      .payment-options {
        margin-top: 2px;
      }

      .payment-icon {
        margin-right: 10px;
        font-size: 20px;
      }
    }
  }

  .card-item-price {
    background: #c6c7c9 !important;

    .custom-sub-title {
      font-size: 12px;
      color: #6f6f73;

      span {
        color: #fff;
        font-weight: 600;
        font-size: 12px;
        margin-right: 4px;
      }
    }

    hr {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
    }

    .title-bold {
      font-weight: 600;
      color: #fff;
      margin-right: 5px;
    }
    .item-sub-title {
      color: #6f6f73;
    }

    .btn-plan {
      display: flex;
      justify-content: center;
    }
  }
  .plan-selected {
    background: #ffffff !important;
    border: 1px solid #dee6ed;
    .title-bold {
      color: #1f212e;
    }

    .custom-sub-title {
      span {
        color: #f5c451;
      }
    }

    .custom-title {
      color: #1f212e;
    }

    .price-title {
      color: #1f212e;
    }

    hr {
      border-top: 1px solid #1f212e;
    }
  }

  .card-item-price::before,
  .card-item-price::after {
    content: "";
    z-index: 1;
    position: absolute;
    width: 50%;
    margin: 0 10%;
    /* display: flex;
    justify-content: center; */
  }

  .card-item-price::before {
    /* border-top: 1px solid; */
    left: 0;
    right: 0;
    top: 0;
    height: 2px;
  }

  .card-item-price::after {
    border-radius: 60%;
    left: 15%;
    right: 15%;
    height: 60px;
    border-bottom: 1px solid #dee6ed;
    top: -40px;
    background-color: #ffffff;
  }

  .row-price-card {
    margin: 0 5px;
  }

  .card-item-price.card {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
  }
}
