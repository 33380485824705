.react-table {
  .disabled {
    cursor: not-allowed !important;
    opacity: 0.3 !important;

    i {
      cursor: not-allowed !important;
    }
  }

  .disabled {
    .btn.btn-icon.btn-sm {
      cursor: not-allowed !important;
      opacity: 0.3 !important;
    }
  }

  .font-awesome {
  }

  .font-awesome:hover {
    color: #fff;
  }

  .nav {
    justify-content: center;
  }

  b {
    margin-right: 10px;
  }

  .right {
    text-align: right;
    padding: 0;
  }

  .left {
    text-align: left;
    padding-left: 10px;
  }

  .modal-content {
    height: 575px;
    background-color: #191f31;
  }

  .modal-header {
    h6 {
      color: #ffffffcc !important;
    }
  }

  .modal-body {
    overflow: hidden;
    height: 400px;
    padding: 20px 30px 0px 30px;

    .card-body {
      /* height: 450px; */
      height: 430px;
      /* border: 1px solid #fafafacc;
      border-radius: 4px; */
    }

    svg {
      margin: 2px 0 0 10px;
    }
  }

  .modal-footer {
    padding: 15px;
  }

  label.success {
    color: $success;
  }
  label.warning {
    color: $warning;
  }
  label.danger {
    color: $danger;
  }

  .ReactTable .rt-td .custom-switch {
    display: flex;
  }

  /*.rt-thead {
    :nth-child(n + 4) {
      margin: 0 !important;
    }
  }*/

  .disabled .custom-switch .custom-control {
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.3 !important;
  }

  .danger {
    color: #f63b3b;
  }

  .dropdown.show .dropdown-menu {
    width: 100%;
    height: auto;
    background-color: #27293d;
    border: 1px solid;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .dropdown-menu:before {
    color: #27293d;
  }

  .form-group {
    label {
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }

  hr {
    border-color: #ffffff93;
  }
  .show > .btn-secondary.dropdown-toggle {
    background-color: unset;
  }
}

.shadow-top {
  position: relative;
  z-index: 2;
  box-shadow: 0px 5px 5px -5px #000 inset;
  height: 20px;
  margin-bottom: -20px;
  margin-right: 10px;
}

.shadow-bottom {
  position: relative;
  z-index: 2;
  box-shadow: 0px -5px 5px -5px #000 inset;
  height: 20px;
  margin-top: -20px;
  margin-right: 10px;
  overflow-y: hidden;
}

.no-overflow {
  overflow-y: hidden !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.55);
  background: rgba(0, 0, 0, 0.05);
  z-index: 2;
}

.ReactTable .rt-tbody .rt-tr {
  height: 50px;
  overflow-y: hidden;
}

.remove-filter {
  margin: 0 15px 0 0;
  padding: 11px;
  min-width: 40px;
  width: 10%;
}

.disabled.custom-switch.custom-control > label,
input::before {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.custom-label {
  display: flex;

  label {
    margin: -1px 0 0 4px !important;
    font-size: 10px !important;
  }
}
