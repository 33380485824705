.copyright .nav-link {
  display: contents;
}

.card-header-sp {
  height: 75px!important;
}

.card-header-sp img {
  max-width: 160px;
}

.enroll-security-key-page {
  .card-header-sp img {
    max-width: 120px;
  }
}

#bg-video {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
}

.white-content .full-page {

  background: #4B08A1; 
  background-image: url("../img/background.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}