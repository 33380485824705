.sweet-alert input.form-control {
  color: #fff !important;
}

/* Custom marngins & paddings */
.m0 {
  margin: 0 !important;
}

.left5 {
  margin-left: 5px;
}

.left25 {
  margin-left: 25px;
}

.top0 {
  margin-top: 0 !important;
}

.top5 {
  margin-top: 5px !important;
}

.top8 {
  margin-top: 8px !important;
}

.top10 {
  margin-top: 10px !important;
}

.right3 {
  margin-right: 3px;
}

.left4 {
  margin-left: 4px;
}

.right5 {
  margin-right: 5px !important;
}

.bottom0 {
  margin-bottom: 0px;
}

.bottom10 {
  margin-bottom: 10px !important;
}

.bottom15 {
  margin-bottom: 15px !important;
}

.pBotttom0 {
  padding-bottom: 0 !important;
}

.pBottom15 {
  padding-bottom: 15px !important;
}

.p0 {
  padding: 0 !important;
}

.p10 {
  padding: 15px !important;
}

/* Float */
.left {
  float: left;
}
.right {
  float: right;
}

/* Align items */
.center-sp {
  display: flex;
  justify-content: center;
}

.flex {
  display: flex;

  .info-list {
    width: 100%;
  }
}

.flex-sp {
  display: flex;
  align-items: center;
}

.align-center {
  text-align: center;
}

/* Font colors */
.font-primary {
  color: $primary !important;
}

.semi-bold {
  font-weight: 600;
}

.danger-txt {
  color: #ff4757;
}

.warning-txt {
  color: #f5d142;
}

.success-txt {
  color: #23ad5c;
}

.info-txt {
  color: $primary;
}

.react-select__input-container {
  color: #fff !important;
}

.hr-ligth {
  border-color: rgba(255, 255, 255, 0.5);
}

/* Font borders */
.border-black {
  border: 1px solid #222a42;
}
.border-danger {
  border: 1px solid #f63b3b;
}
.cursor-p {
  cursor: pointer;
}
.form-formik {
  label {
    font-size: 13px !important;
  }

  .error {
    color: #ff4757;
    font-size: 11px;
    margin: -8px 0 5px 0;
  }

  input {
    width: 100%;
    padding: 10px 18px;
    font-size: 0.75rem;
    color: #fff;
  }

  input:focus-visible {
    border: 1px solid #1d8cf8;
    outline: unset;
  }

  .password-strength {
    border-radius: 3px;
    width: 34%;
    height: 6px;
    background-color: #efefef;
    border: 1px solid #ced4da;
    margin: -5px 2px 0px 0;
  }

  .display-strength {
    display: flex;
    justify-content: center;
    margin: 5px 0;
  }

  .flex {
    display: flex;
    width: 100.4%;
    /* align-items: center; */
  }

  .flex-center {
    width: 100%;
    align-items: center;
  }

  .strength-txt {
    font-size: 13px;
    font-weight: 400;
    margin-left: 5px;
  }

  .input-group .form-group {
    display: flex;
  }

  .usr-profile {
    margin-right: 5px;
    border: 1px solid rgba(13, 110, 253, 0.7);
    border-radius: 0.25rem;
    width: 40px !important;
    padding: 4px;
    height: 40px;
    cursor: pointer;
  }
}

.new-secret {
  hr {
    border-top: 1px solid #2b3553;
  }

  .react-select__control--is-disabled {
    background-color: #191f31 !important;
    border: 1px solid #1d1d2c !important;
    opacity: 0.5;
  }

  .react-select__control--is-focused {
    border: 1px solid #2b3553 !important;
  }

  .space-between {
    justify-content: space-between;
  }

  .box-label {
    display: flex;
    height: 23px;
  }
}

.availability {
  .error {
    margin: 0 !important;
  }
}

.custom-card {
  box-shadow: none !important;
  background: #191f31 !important;
}

/* Forgot - For Col */
.fixed-height {
  height: 100px;
}

.date-column {
  label {
    font-size: 0.85rem !important;
  }
}

.error-tab {
  .nav-link {
    background-color: #ff4757 !important;
    color: #fff !important;
    /*animation: beat 0.25s infinite alternate;*/
    transform-origin: center;
  }

  .nav-link.active {
    background-color: #f63b3b !important;
    background-image: none !important;
    /*animation: beat 0.25s infinite alternate;*/
    transform-origin: center;
  }

  /* Heart beat animation */
  @keyframes beat {
    to {
      transform: scale(1.1);
    }
  }
}

.bold {
  font-weight: bold;
}