.header {
  position:relative;
  padding: 20px 50px;
  position:fixed;
  top:0px;
  left:0px;
  background-color:#f1f3f9;
  width:100%;
  border-bottom:1px solid #d2d5de;
  z-index:9999;
  transition:all 0.3s ease-in-out;
}
.header.dark{
  background-color: #09182c;

}

.header .header_content{
    display: flex;
    justify-content:space-between;
    align-items: center;
    width:100%;
    max-width:1950px;
    margin:0px auto;
}

.header .logo_wrap img {
  width: 180px;
  margin-bottom:-5px;
  transition:all 0.3s ease-in-out;
}
.header .nav_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 34px;
}
.header .nav_wrap a{
    transition:all 0.3s ease-in-out;
}

.header .nav_wrap a:hover{
    color:#8139f9;
}
.header .nav_wrap a.active{
   color:#8139f9;
}

.header .nav_wrap button{
    padding:12px 25px;
    background-color:#000;
    color:#fff;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size:16px;
    font-weight:700;
    border-style: none;
    transition:all 0.3s ease-in-out;
}
.header .nav_wrap button:hover{
    background-color:#8139f9;
}

.header .header_content .menu{
  position:relative;
  height:41px;
  width:41px;
  border:1px solid #02f3a2;
  background-color:#09182c;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  gap:5px;
  transition:all 0.3s ease-in-out;
  display: none;
}
.header .header_content .menu .l{
height:2px;
 width:20px;
 background-color:#f1f3f9;
 transition:all 0.3s ease-in-out;
}
.header .header_content .menu.close .cl{
  display: none;
}

.header .header_content .menu.close .fl{
  transform: rotate(-135deg);
}
.header .header_content .menu.close .sl{
  transform: rotate(135deg);
}
.header .header_content .menu.close .l{
position:absolute;
}

.small_menu{
position:fixed;
left:0px;
top:74px;
z-index:999;
background-color:#09182c;
height:calc(100vh - 74px);
width:100%;
transition:all 0.3s ease-in-out;
display: flex;
flex-direction: column;
justify-content: space-between;
min-height:600px;
width:100%;

}
.small_menu svg.bg{
  position:absolute;
  bottom:0px;
  left:0px;
}

.small_menu .navs{
  display: flex;
  flex-direction: column;
  padding:20px 20px;
  position:relative;
  z-index: 999999;
}

.small_menu .navs a{
  color:#fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:16px 0px;
  font-size:16px;
  line-height:24px;
  font-weight: 400;

}
.small_menu .btn_wrap{
  display: flex;
  flex-direction: column;
  align-items: center;

}

.small_menu .btn-signin-menu {
  display: block!important;
}

.small_menu .btn-signin-menu .btn_wrap button.small_btn{
  padding:16px 18px;
  background-color:#8139f9;
  z-index:999999;
  border:4px solid #02f3a2;
  color: #fff;
  width:100%;
}

.small_menu .btn_wrap .line{
height:24px;
width:100%;
background-color:#02f3a2;
margin-top:40px;
}
.small_menu .btn_wrap .light_line{  
  height:24px;
  width:100%;
  background-color:#61f3c5;
}

body {
  background-color: #fff!important;
}
@media only screen and (max-width:1100px){
  .header{
    padding:16px 20px!important;
  }
  
  .header .header_content .menu{
    display: flex;
  }
  .header .logo_wrap img{
    width:170px;
  }
}


@media only screen and (max-width:1023px){
  .header{
    padding:20px 40px;
  }
}

@media only screen and (max-width:767px){
  .header{
    padding:20px 15px;
  }
}

