.secret-detail {
  hr {
    /* border-top: 0.02rem solid #dee2e6 !important; */
    border-top: 1px solid #dee2e6;
    border-color: rgba(255, 255, 255, 0.1);
  }
  .right {
    text-align: right;
  }
}

.secret-box-details {
  min-height: 300px;
}

.access-logs-container {
  height: 230px;
  overflow: auto;
}

.access-logs-container .ReactTable {
  min-width: 590px;
  overflow: auto;
}

.per-8 {
  width: 8% !important;
  flex: none !important;
}

.per-9 {
  width: 9% !important;
  flex: none !important;
}

.per-10 {
  width: 10% !important;
  flex: none !important;
}

.per-11 {
  width: 11% !important;
  flex: none !important;
}

.per-12 {
  width: 12% !important;
  flex: none !important;
}

.per-15 {
  width: 15% !important;
  flex: none !important;
}

.per-20 {
  width: 20% !important;
  flex: none !important;
}

.per-22 {
  width: 22% !important;
  flex: none !important;
}

.per-30 {
  width: 30% !important;
  flex: none !important;
}

.per-40 {
  width: 40% !important;
  flex: none !important;
}

.per-50 {
  width: 50% !important;
  flex: none !important;
}

.per-60 {
  width: 60% !important;
  flex: none !important;
}

.per-70 {
  width: 70% !important;
  flex: none !important;
}

.per-80 {
  width: 80% !important;
  flex: none !important;
}

.per-90 {
  width: 90% !important;
  flex: none !important;
}

.per-100 {
  width: 100% !important;
  flex: none !important;
}

.nav-show-sm {
  display: none;
}

@media (max-width: 509px) {
  .nav-hide-sm {
    display: none;
  }

  .nav-show-sm {
    display: block;
  }
}

@media (min-width: 576px) {
  .secret-list.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}
