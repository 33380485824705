.full-page {
  

  &.enroll-security-key-page,
  &.pricing-page,
  &.login-page,
  &.lock-page,
  &.forgot-page,
  &.resend-page,
  &.register-page {
    min-height: 100vh;
    background: #4B08A1; 
    background-image: url("../img/background.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .logo-login {
    margin-top: 25px;
  }

}
