.full-page .footer-auth {
    position: relative !important;
    padding: 40px 0!important;
}

.content_sec {
    max-width: 1320px;
    padding: 0px 60px;
    margin: 0px auto;
}

.footer-auth{
    background-color:#09182c!important;
    padding-bottom:40px!important;
    padding-top:40px!important;
}



.footer-auth .content_sec .link_wrap{
display: flex!important;
/*justify-content: space-around!important;*/
align-items: flex-start!important;
border-bottom: 1px solid;
    padding-bottom: 20px;
}

.footer-auth .content_sec .link_wrap .links_row{
    display: flex!important;
    flex-direction: column!important;
    align-items: flex-start!important;
}

.footer-auth .content_sec .link_wrap .links_row p{
    color:#556072!important;
    font-size:16px!important;
    line-height: 21px!important;
    font-weight:500!important;
    letter-spacing: 0.32px!important;
}

.footer-auth .content_sec .link_wrap .links_row a{
    font-size:16px!important;
    line-height: 21px!important;
    font-weight:500!important;
    letter-spacing: 0.32px!important;
    color:#fff!important;
    margin-top:20px!important;
    text-decoration: none!important;
    transition:all 0.3s ease-in-out!important;
}
.footer-auth .content_sec .link_wrap .links_row a:hover{
color:#02f3a2!important;
}

.footer-auth .content_sec .mid_line{
display: flex!important;
justify-content: space-between!important;
align-items: center!important;
margin-top:150px!important;
padding-bottom:60px!important;
border-bottom:1px solid #18263a!important;
gap:40px!important;
}

.footer-auth .content_sec .mid_line .media{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:8px;  
}

.footer-auth .content_sec .mid_line .media a{
    display: grid;
    place-items: center;
    height:40px;
    width:40px;
    border:1px solid #fff;
}

.footer-auth .content_sec .mid_line .media a svg.hover{
    display: none;
    transition:all 0.3s ease-in-out;
}

.footer-auth .content_sec .mid_line .media a:hover svg.hover{
    display: block;
}
.footer-auth .content_sec .mid_line .media a svg.normal{
    transition:all 0.3s ease-in-out;
}

.footer-auth .content_sec .mid_line .media a:hover svg.normal{
    display: none;
    transition:all 0.3s ease-in-out;
}

.footer-auth .content_sec .mid_line .media a:hover{
    border:1px solid #02f3a2;
}
.footer-auth .content_sec .mid_line h3{
    color:#fff;
}

.footer-auth .content_sec .mid_line form{
    width:450px;
}

.footer-auth .content_sec .mid_line .field_wrap{
    padding:5px 5px 5px 28px;
    display: flex;
    align-items: center;
    height:62px;
    border:1px solid #556072;
    width:100%;
}

.footer-auth .content_sec .mid_line .field_wrap input{
    height:100%;
    width:calc(100% - 150px);
    background-color:transparent;
    border-style: none;
    color:#556072;
    font-size:16px;
    font-weight:500;
    line-height: 21px;

}
.footer-auth .content_sec .mid_line .field_wrap input::placeholder{
    text-transform: capitalize;
}

.footer-auth .content_sec .mid_line .field_wrap input:focus{
    outline: none;
}

.footer-auth .content_sec .mid_line .field_wrap button{
    height:100%;
    width:150px;
    background-color:#7829f9;
    color:#fff;
    border-style: none;
    transition:all 0.3s ease-in-out;
    font-size:16px;
    line-height: 24px;
    font-weight:400;
    text-transform: capitalize;
}
.footer-auth .content_sec .mid_line .field_wrap button:hover{
background-color:#fff;
color:#18263a;
}

.footer-auth .content_sec p.copyright{
text-align: center;
font-size:14px;
line-height:21px;
letter-spacing: 0.56px;
font-weight:500;
color:#556072;
margin:40px 0px;
}

.footer-auth .bottom_line{
    display: flex!important;
    justify-content: space-around!important;
    align-items: center!important;
    grid-gap: 20px;
    gap: 20px;
}
.footer-auth .bottom_line a{
font-size:14px!important;
line-height:21px!important;
letter-spacing: 0.56px!important;
font-weight:500!important;
color:#556072!important;
transition:all 0.3s ease-in-out!important;
}
.footer-auth .bottom_line a:hover{
    color:#02f3a2!important;
}

.footer-auth .linkedin-newsletter button {
    padding:12px 25px;
    background-color: #8139f9;
    color:#fff;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size:16px;
    font-weight:700;
    border-style: none;
    transition:all 0.3s ease-in-out;
    margin-top: 20px;
    width: 100%;
}

.footer-auth .linkedin-newsletter button:hover {
    background-color: #000;
}

.footer-auth .copyright {
    float: none!important;
}

@media only screen and (max-width:1200px){
    .footer-auth .content_sec .mid_line{
        flex-direction: column;
        align-items: center;
    }
    .footer-auth .content_sec .mid_line h3{
        text-align: center;
    }
    .footer-auth .content_sec .mid_line form{
        width:80%;
    }
}

@media only screen and (max-width:1200px){
    .footer-auth {
        padding: 40px 0!important;
    }
    .footer-auth .content_sec .mid_line .form{
        width:100%;
    }
    .footer-auth .content_sec .mid_line{
        gap:32px;
    }
}

@media only screen and (max-width:900px){
    .footer-auth {
        padding: 40px 0!important;
    }
    .footer-auth .content_sec .link_wrap {
        display: none;
    }
    .footer-auth .content_sec .mid_line{
        margin-top:100px;
    }
    .footer-auth .bottom_line{
        display: grid!important;
        grid-template-columns: 1fr 1fr 1fr!important;
        gap:20px!important;
    }
}

@media only screen and (max-width:600px){
    .footer-auth {
        padding: 40px 0!important;
    }

    .footer-auth .content_sec .link_wrap{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:25px;
    }
    .footer-auth .content_sec .mid_line form{
        width:100%;
    }
    .footer-auth .content_sec .mid_line .field_wrap{
        padding:3px 3px 3px 15px;
        height:50px;
    }
    .footer-auth .content_sec .mid_line .field_wrap input{
        width:calc(100% - 100px);
        font-size:14px;
    }
    .footer-auth .content_sec .mid_line .field_wrap input::placeholder{
       font-size:14px;
    }
    .footer-auth .content_sec .mid_line .field_wrap button{
        width:100px;
        font-size:14px;
    }
    .footer-auth .content_sec .mid_line{
        padding-bottom:40px;
    }
    .footer-auth .content_sec p.copyright{
        margin:40px 0px;
    }
}
@media only screen and (max-width:550px){
    .footer-auth {
        padding: 40px 0!important;
    }
    .footer-auth .bottom_line{
        display: grid!important;
        grid-template-columns: 1fr 1fr!important;
        gap:10px!important;
    }
}

@media only screen and (max-width:350px){
    .footer-auth {
        padding: 40px 0!important;
    }
    .footer-auth .bottom_line{
        display: grid!important;
        grid-template-columns: 1fr!important;
        gap:7px!important;
    }
    .footer-auth .bottom_line a{
        text-align: center!important;
    }
}